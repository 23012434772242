body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "SoDoSans";
  src: local("SoDoSans"),
   url("./assets/fonts/SoDo-Sans/SoDoSans-Regular.ttf") format("truetype");
  font-style: normal;
  font-weight: 400;
 }

 @font-face {
  font-family: "SoDoSans";
  src: local("SoDoSans"),
   url("./assets/fonts/SoDo-Sans/SoDoSans-SemiBold.ttf") format("truetype");
  font-style: normal;
  font-weight: 600;
 }

 @font-face {
  font-family: "SoDoSans";
  src: local("SoDoSans"),
   url("./assets/fonts/SoDo-Sans/SoDoSans-Bold.ttf") format("truetype");
  font-style: normal;
  font-weight: 700;
 }