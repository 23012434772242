.uploader-item {
    position: relative;
}

.image-preview-container {
    position: relative;
    overflow: hidden;
    border: 2px dashed #ddd;
    border-radius: 5px;
    background-color: #f9f9f9;
    transition: border-color 0.3s ease;
}

.image-preview {
    display: block;
    max-width: 100%;
    height: auto;
    transition: transform 0.3s ease;
    cursor: pointer;
}

.image-preview:hover {
    transform: scale(1.05);
}

.image-preview-delete-container1 {
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 50%;
    padding: 5px;
}

.image-preview-delete-button {
    color: #666;
    cursor: pointer;
    padding: 3px;
}

.image-preview-delete-button .MuiSvgIcon-root {
    font-size: 14px;
    /* Reduce the size of the icon */
}


.image-preview-delete-button:hover {
    color: #333;
}