.MuiColorInput-ColorSpace {
  background-image: linear-gradient(to top, #555555, transparent),
    linear-gradient(to right, 
      rgb(255, 0, 0) 0%, 
      rgb(255, 255, 0) 17%, 
      rgb(0, 255, 0) 33%, 
      rgb(0, 255, 255) 50%, 
      rgb(0, 0, 255) 67%, 
      rgb(255, 0, 255) 83%, 
      rgb(255, 0, 0) 100%) !important
}

.MuiColorInput-HueSlider {
  display: none !important; 
}

.MuiColorInput-AlphaSlider {
  display: none !important;
}